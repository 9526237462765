<template>
  <div>
    <el-card>
      <div class="top-box">
        <div class="top-title">老兵后台系统数据总览</div>
        <el-form class="term line demo-form-inline" v-model="indexForm" inline size="small">
          <el-form-item>
            <el-col :span="11">
              <el-form-item>
                <el-date-picker type="date" placeholder="选择日期" v-model="indexForm.start" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-form-item>
                <el-date-picker type="date" placeholder="选择日期" v-model="indexForm.end" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="submit" @click="search">查询</el-button>
            <el-button class="grey" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="main-box">
        <div v-for="(item, index) in contentList" :key="index" class="box-card">
          <div class="position-box">
            <div class="flex-box">
              <img :src="item.imgUrl">
              <div class="content-box">
                <div style="font-size: 40px; color: #1666F3">{{ item.count }}</div>
                <div style="font-size: 20px; color: #333333">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getIndexInfo } from "@/utils/api";
export default {
  data() {
    return {
      contentList: [
        {
          imgUrl: require("@/assets/images/index/icon01.png"),
          title: "注册用户数",
          count: 0,
        },
        {
          imgUrl: require("@/assets/images/index/icon02.png"),
          title: "PV",
          count: 0,
        },
        {
          imgUrl: require("@/assets/images/index/icon03.png"),
          title: "UV",
          count: 0,
        },
        {
          imgUrl: require("@/assets/images/index/icon04.png"),
          title: "点赞量",
          count: 0,
        },
        {
          imgUrl: require("@/assets/images/index/icon05.png"),
          title: "分享量",
          count: 0,
        },
      ],
      indexForm: {
        start: "",
        end: "",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getIndexInfo(this.indexForm);
      console.log(res);
      const { code, data } = res;
      if (code === 200) {
        this.contentList[0].count = data.userCount || 0;
        this.contentList[1].count = data.pv || 0;
        this.contentList[2].count = data.uv || 0;
        this.contentList[3].count = data.starNum || 0;
        this.contentList[4].count = data.shareNum || 0;
      }
    },
    search() {
      this.getData();
    },
    reset() {
      this.indexForm.start = "";
      this.indexForm.end = "";
      this.getData();
    },
  },
};
</script>
<style lang="less" scoped>
.top-box {
  text-align: center;
  color: #1e1e1e;
  .top-title {
    font-size: 30px;
    margin: 20px 0;
  }
}
.main-box {
  margin-top: 20px;
  padding: 0 30px;
  display: flex;
  flex-wrap: wrap;
  .box-card {
    width: 22%;
    height: 160px;
    margin: 15px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 9px;
    background: #e9e9e9;
    position: relative;
    z-index: 1;
    .position-box {
      width: 100%;
      height: 100%;
      border: 1px solid #d9d9d9;
      background: #ffffff;
      border-radius: 9px;
      position: absolute;
      right: 7px;
      bottom: 7px;
      display: flex;
      align-items: center;
      .flex-box {
        width: 350px;
        height: 150px;
        display: flex;
        align-items: center;
        margin-left: 50px;
        .content-box {
          width: 150px;
          height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
        }
      }
    }
  }
}
</style>
